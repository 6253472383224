import { useQuery } from 'react-query';
import { getTeam } from '../../services/teamApi';
import Alert from '../shared/Alert';
import Loading from '../shared/Loading';
import Title from '../shared/Title';
import ItemCard from './ItemCard';

const ManagementContainer = () => {
  const { isLoading, isError, data } = useQuery(['sherpas', { filters: { default: `$filter=TypePosition eq 'administrative'` }, order: [{ by: 'Name', type: 'asc' }] }], getTeam, {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  return (
    <>
      <Title text1="Management" text2="Team" colorForText={2} isLight={true} />
      {isLoading ? (
        <Loading isLight={true} />
      ) : isError ? (
        <Alert open={isError} severity="warning" message="Error loading data, try again later" />
      ) : data && data.data.length > 0 ? (
        <div className="grid md:grid-cols-5 grid-cols-3 gap-4">
          {data.data.map((user, index) => (
            <ItemCard
              key={user.Id}
              link={user.LinkedIn}
              name={user.Name}
              photo={user.PhotoUrl}
              position={user.Position}
              isCouncil={false}
              classes=""
            />
          ))}
        </div>
      ) : (
        <Alert open={true} message="No information available at this time" severity="info" />
      )}
    </>
  );
};

export default ManagementContainer;
